@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
  	font: sans-serif;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/* maincolor-1: #2437AB;
maincolor-2: #62D2EF;

seccolor-1: #FF7A06;
seccolor-2: #FFE787; */
body {
  font-family: "Quicksand", sans-serif;
  font-weight: 300;
  color: white;
  background-color: #1E1E2C;
}

body ::selection {
  background-color: #D6AF6B;
}

.main {
  display: flex;
  margin: auto;
  flex-wrap: nowrap;
  justify-content: center;
  overflow: hidden;
  height: 90%;
}

.main2 {
  display: inline-block;
  width: auto;
  height: auto;
}

.footer {
  width: 100%;
  bottom: 0px;
  color: white;
  text-align: center;
  margin: 5px 0px;
  margin-top: 0px;
  transition: 1s;
}

.footer a {
  color: #D6AF6B;
  text-decoration: none;
}

.mobile-footer {
  display: block;
  width: 100%;
  height: 3vh;
  bottom: 0px;
  margin: 5px 0px;
  margin-top: 35px;
  color: white;
  text-align: center;
}

.mobile-footer a {
  color: #D6AF6B;
  text-decoration: none;
}

.appInfo {
  width: 500px;
  display: none;
  position: absolute;
  margin: 10px;
  z-index: 15;
  background-color: #252a42;
  transition: 2s;
}

.sidebar-header {
  display: block;
  width: 100%;
  max-width: 360px;
  height: auto;
  padding-top: 5px;
}

.sidebar-header .MuiButtonBase-root {
  display: inline-block;
  position: relative;
  top: -15px;
  color: #5B5768;
  margin: 0px;
  padding: 0px;
}

.sidebar-header .header-title {
  display: inline-block;
  padding: 10px;
  padding-bottom: 5px;
}

.sidebar-header .header-title h1 {
  text-transform: uppercase;
  font-weight: lighter;
  font-size: 2.7rem;
  line-height: 2rem;
  letter-spacing: 2px;
  color: #FFE395;
  display: inline;
  font-weight: lighter;
  cursor: default;
  transition: 0.2s;
}

.sidebar-header .header-title h1:hover {
  color: white;
}

.sidebar-header .header-title p {
  font-size: 0.9rem;
  margin: 5px 0px;
  margin-bottom: 0px;
}

.sidebar-plus-image {
  display: flex;
  width: auto;
  justify-content: center;
}

.sidebar {
  font-family: "Quicksand", sans-serif;
  position: relative;
  width: 360px;
  height: 94vh;
  font-size: 13px;
  font-weight: 300;
}

.sidebar .searchContainer {
  font-family: "Quicksand", sans-serif;
  padding: 10px;
  padding-top: 0px;
  width: auto;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.sidebar form {
  display: flex;
  width: 100%;
  margin: 2px 0px;
}

.sidebar .searchInput {
  font-family: "Quicksand", sans-serif;
  background: #D6AF6B;
  font-weight: bold;
  width: 200px;
  border: none;
  padding: 5px 10px;
  border-radius: 10px;
}

.sidebar .searchButton {
  color: #D6AF6B;
  font-family: "Quicksand", sans-serif;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  padding: 5px 20px;
  margin: 0px 5px;
  background-color: #5B5768;
}

.sidebar .searchButton:hover {
  background-color: #574744;
  cursor: pointer;
}

.sidebar .resultsList {
  display: block;
  position: relative;
  height: auto;
  height: 90%;
  max-height: 98%;
  list-style-type: none;
  text-align: left;
  padding: 10px;
  border-radius: 5px;
  /* margin-bottom: 30px; */
  background-color: #343044;
  overflow-y: scroll;
}

.sidebar .resultsList li {
  display: flex;
  padding: 3px;
  align-items: center;
  cursor: pointer;
  margin: 3px;
  border-radius: 10px;
  background-color: #2B2637;
}

.sidebar .resultsList li:hover {
  background-color: #36293b;
}

.sidebar .resultsList li h3 {
  font-weight: 400;
  font-size: 16px;
  padding: 2px 2px;
}

.sidebar .resultsList li h5 {
  font-weight: 300;
  /* font-size: 1.5rem; */
  padding: 2px 2px;
  color: #ffe787;
}

.sidebar .searchResultImage {
  display: inline-block;
  padding: 5px;
  height: 50px;
  width: auto;
}

.sidebar .searchResultImage img {
  width: 50px;
  height: auto;
}

span {
  margin: 5px;
}

::-webkit-scrollbar {
  display: none;
}

.imageContainer {
  position: relative;
  margin: auto 0;
  transition: 1s;
  /* overflow: hidden; */
}

.generatedImage {
  /* width: 900px;
    height: 900px; */
  border: 1px solid #3d3d41;
  /* margin: auto; */
}

svg {
  width: 100%;
  height: auto;
  fill: #3d3d41;
}

.A,
.B,
.C,
.D,
.E,
.BG {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
}

h3 {
  font-weight: 700;
}

.A {
  z-index: 10;
}

.B {
  z-index: 8;
}

.C {
  z-index: 6;
}

.D {
  z-index: 4;
}

.E {
  z-index: 2;
}

.BG {
  z-index: 0;
}

.A img,
.B img,
.C img,
.BG img {
  width: 100%;
  height: auto;
}

.songTitleDisplay {
  margin: 10px;
  text-align: center;
}

.songTitleDisplay h3 {
  font-weight: 400;
  margin: 5px;
}

.songTitleDisplay h5 {
  font-weight: 300;
}

.image-element-enter {
  transform: scale(0.8);
  opacity: 0;
  transition: 1ms;
}

.image-element-enter-active {
  transform: scale(0.8);
  opacity: 0;
  transition: 1000ms;
}

.image-element-exit {
  transform: scale(0.8);
  opacity: 1;
  transition: 1000ms;
}

.image-element-exit-active {
  transform: scale(1);
  opacity: 1;
  transition: 1000ms;
}

.generatedImage {
  display: flex;
  margin: auto;
  width: 700px;
  height: 700px;
  border: 1px solid #3d3d41;
  overflow: hidden;
  /* transform: scale(1.1); */
}

svg {
  width: 100%;
  height: auto;
  fill: #3d3d41;
}

.A,
.B,
.C,
.D,
.E,
.BG {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  margin: auto;
  /* animation: fadeIn 2s 1; */
}

@-webkit-keyframes move1 {
  0% {
    transform: translate(0px, 5px);
  }
  25% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, -5px);
  }
  75% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(0px, 5px);
  }
}

@keyframes move1 {
  0% {
    transform: translate(0px, 5px);
  }
  25% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, -5px);
  }
  75% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(0px, 5px);
  }
}

@-webkit-keyframes pulse1 {
  0% {
    transform: scale(1);
  }
  22% {
    transform: scale(1.02);
  }
  50% {
    transform: scale(1.05);
  }
  80% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pulse1 {
  0% {
    transform: scale(1);
  }
  22% {
    transform: scale(1.02);
  }
  50% {
    transform: scale(1.05);
  }
  80% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes spin1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.A {
  z-index: 10;
  -webkit-animation: pulse1 6s linear infinite;
          animation: pulse1 6s linear infinite;
}

.B {
  z-index: 8;
  -webkit-animation: move1 5s linear infinite;
          animation: move1 5s linear infinite;
}

.C {
  z-index: 6;
  -webkit-animation: move1 5.5s linear infinite;
          animation: move1 5.5s linear infinite;
}

.D {
  z-index: 4;
  -webkit-animation: spin1 90s linear infinite;
          animation: spin1 90s linear infinite;
}

.E {
  z-index: 2;
  -webkit-animation: spin1 120s linear infinite;
          animation: spin1 120s linear infinite;
}

.BG {
  z-index: 1;
  -webkit-animation: spin1 180s linear infinite;
          animation: spin1 180s linear infinite;
}

@media only screen and (max-width: 512px) {
  .imageContainer {
    -ms-grid-row-align: center;
    align-self: center;
    transform: scale(0.7);
    margin: -90px 0;
  }
}

@media only screen and (min-width: 512px) and (max-width: 768px) {
  .imageContainer {
    -ms-grid-row-align: center;
    align-self: center;
    transform: scale(0.9);
    margin: -10px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .imageContainer {
    -ms-grid-row-align: center;
    align-self: center;
    transform: scale(1);
    margin: 10px auto;
  }
}

@media only screen and (max-width: 1024px) {
  .main {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin: auto;
    justify-content: center;
    overflow: hidden;
    height: 90%;
  }
  .main2 {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
  }
  .sidebar-header {
    position: relative;
    left: 5%;
    -ms-grid-row-align: center;
    align-self: center;
  }
  .sidebar {
    width: 90%;
    height: 30vh;
    margin: auto;
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: 300;
  }
  .sidebar form {
    justify-content: center;
  }
  .sidebar form .searchInput {
    width: 70%;
  }
}

@media only screen and (min-width: 1024px) {
  .sidebar {
    height: 781px;
  }
  .imageContainer {
    transform: scale(1.1);
    margin: 50px 50px;
  }
}
/*# sourceMappingURL=App.css.map */
